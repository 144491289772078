import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
import { RemoveNullValues } from '../../utils/removeNullableValues';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    agencies: [],
    agency: null,
    newAgencyId: null,
    addAgencyUser: false,
    meta: { currentPage: 0, nextPage: false, prevPage: false, totalPages: 0, totalRecords: 0 },
    userTimezone: {},
};
// ----------------------------------------------------------------------
export const getAgencies = createAsyncThunk('agency/getAgencies', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', filterName = '', isPaging = true, }) => {
    let data;
    const queryParams = {
        search: {
            name: filterName,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    try {
        const url = createDynamicURL('/agencies', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const userTimeZone = createAsyncThunk('agency/userTimeZone', async () => {
    let data;
    try {
        const response = await axios.get('/me');
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createAgency = createAsyncThunk('agency/createAgency', async (Data, thunkApi) => {
    let data;
    try {
        const formData = serialize({
            stamp: Data.stamp,
            name: Data.name,
            timezoneId: Data.timezoneId,
            currencies: JSON.stringify(Data.currencies),
            configValues: JSON.stringify(Data.configValues),
            ...RemoveNullValues({
                description: Data.description,
                phoneNumber: Data.phoneNumber,
                logo: Data.logo,
                defaultBankAccountId: Data?.defaultBankAccountId,
            }),
        });
        const response = await axios.post('/agencies', formData);
        data = await response.data;
        if (response.status === 200) {
            thunkApi.dispatch(userTimeZone());
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const updateAgency = createAsyncThunk('agency/updateAgency', async (Data, thunkApi) => {
    let data;
    try {
        const formData = serialize({
            stamp: Data.stamp,
            name: Data.name,
            timezoneId: Data.timezoneId,
            currencies: JSON.stringify(Data.currencies),
            configValues: JSON.stringify(Data.configValues),
            ...RemoveNullValues({
                description: Data.description,
                phoneNumber: Data.phoneNumber,
                logo: Data.logo,
                defaultBankAccountId: Data?.defaultBankAccountId,
            }),
        });
        const response = await axios.put(`/agencies/${Data.id}`, formData);
        data = await response.data;
        if (response.status === 200) {
            thunkApi.dispatch(userTimeZone());
            return { data };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getOneAgency = createAsyncThunk('agencies/getOneAgency', async (id) => {
    let data;
    try {
        const response = await axios.get(`/agencies/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return {
                data,
            };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const deleteManyAgencies = createAsyncThunk('agencies/deleteManyAgencies', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/agencies/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getAgencies({ ...getParams }));
            thunkAPI.dispatch(userTimeZone());
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteAgency = createAsyncThunk('agencies/deleteAgency', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/agencies/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getAgencies({ ...getParams }));
            thunkAPI.dispatch(userTimeZone());
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
// ----------------------------------------------------------------------
const slice = createSlice({
    name: 'agency',
    initialState,
    reducers: {
        getNewAgencyId: (state, action) => {
            state.newAgencyId = action.payload;
        },
        isAddAgencyUser: (state, action) => {
            state.addAgencyUser = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAgencies.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getAgencies.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.agencies = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getAgencies.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createAgency.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createAgency.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createAgency.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteManyAgencies.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteManyAgencies.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteManyAgencies.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteAgency.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteAgency.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteAgency.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(updateAgency.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(updateAgency.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(updateAgency.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getOneAgency.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getOneAgency.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.agency = action.payload.data.data;
        })
            .addCase(getOneAgency.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(userTimeZone.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(userTimeZone.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.userTimezone = action.payload.data;
        })
            .addCase(userTimeZone.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
export const { getNewAgencyId, isAddAgencyUser } = slice.actions;
export const reducer = slice.reducer;
export default slice.reducer;
